import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header.js';
import Footer from './Footer.js';
import HomePage from './HomePage.js';
import MarketsPage from './MarketsPage.js';
import StatsPage from './StatsPage.js';
import TycoonsPage from './TycoonsPage.js';
// import other pages...

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/markets" element={<MarketsPage />} />
        <Route path="/stats" element={<StatsPage />} />
        <Route path="/tycoons" element={<TycoonsPage />} />
        {/* Add more routes as needed */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;