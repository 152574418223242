import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

const apiUrl = "https://tycoon-buddy-ui.replit.app/api/stats";

function StatsPage() {
  const [gameStatsData, setGameStatsData] = useState(null);

  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    } else {
      return num;
    }
  }

  const fiscalStats = [
    { name: 'Taxes', value: 'taxes' },
    { name: 'Unemployment', value: 'unemployment' },
    { name: 'Debt', value: 'debt' },
    { name: 'Inflation', value: 'inflation' },
    { name: 'GDP', value: 'GDP' },
    { name: 'Growth', value: 'GDPgrowth' },
  ];

  let retailConsumptionStats = {};

  if (gameStatsData && gameStatsData.retailconsumption) {
    retailConsumptionStats = {
      "bread": gameStatsData.retailconsumption.bread,
      "cola": gameStatsData.retailconsumption.cola,
      "eggs": gameStatsData.retailconsumption.eggs,
      "chicken": gameStatsData.retailconsumption.chicken,
      "corn": gameStatsData.retailconsumption.corn,
      "flour": gameStatsData.retailconsumption.flour,
      "sugar": gameStatsData.retailconsumption.sugar,
      "custom#13_Soap": gameStatsData.retailconsumption["custom#13_Soap"]
    };
  }

  const keyDisplayNameMapping = {
    "bread": "Bread",
    "cola": "Cola",
    "eggs": "Eggs",
    "chicken": "Chicken",
    "corn": "Corn",
    "flour": "Flour",
    "sugar": "Sugar",
    "custom#13_Soap": "Soap"
  };


  const FiscalStatsCard = ({ name, value }) => {
    const formattedValue = formatNumber(parseInt(gameStatsData[value]) / 100).toLocaleString(undefined);
    const displayValue = (name === 'Taxes' || name === 'Debt' || name === 'GDP') ? `$${formattedValue}` : formattedValue;

    return (
      <div className='bg-gray-700 rounded p-4'>
        <div className=''>
          <p className="text-gray-400 text-lg font-bold">{displayValue}</p>
          <h5 className="mx-auto text-center text-xs font-bold text-slate-200 uppercase">{name}</h5>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchGameStatsData = async () => {
      console.log('Fetching game stats data...');
      try {
        const response = await axios.get(apiUrl);
        console.log('Response:', response);
        if (response && response.data && response.data.length > 0) {
          setGameStatsData(response.data[0]);
          console.log('Game stats data successfully fetched:', response.data[0]);
        }
      } catch (error) {
        console.error('Failed to fetch game stats data:', error);
      }
    };
    fetchGameStatsData();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center overflow-hidden bg-gray-900">
      {gameStatsData ? (
        <section className="mx-auto max-w-full px-4 py-4 text-slate-800 bg-gray-900">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold leading-6 text-gray-300 pt-4 uppercase">
              Stats
            </h1>
            <p className="mt-2 pb-14 text-sm text-gray-500 uppercase">
              Hourly Economic Data
            </p>
          </div>
          <div className="mb-4 grid grid-cols-12 gap-4">
            <BounceCard className="col-span-12 md:col-span-4">
              <CardTitle>Public</CardTitle>
              <p className="text-gray-400 text-sm font-light capitalize">Pulse and Prosperity</p>
              <div className="mt-8 mb-4 grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-6 bg-gray-700 rounded p-4">
                  <p className="text-gray-400 text-2xl font-black">{formatNumber(gameStatsData.population)}</p>
                  <h4 className="mx-auto text-center text-xs font-bold text-slate-200 uppercase">Population</h4>
                </div>
                <div className="col-span-12 md:col-span-6 bg-gray-700 rounded p-4">
                  <p className="text-gray-400  text-2xl font-black">{gameStatsData.QOL}</p>
                  <h4 className="mx-auto text-center text-xs font-bold text-slate-200 uppercase">Well Being</h4>
                </div>
                <div className="pt-6 col-span-12 md:col-span-12 bg-gray-700 rounded p-4">
                  <p className="text-gray-400 text-2xl font-black">${formatNumber(parseInt(gameStatsData.consumerSpending) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  <h4 className="mx-auto text-center text-xs font-bold text-slate-200 uppercase">Consumer Spending</h4>
                </div>
              </div>
            </BounceCard>
            <BounceCard className="col-span-12 md:col-span-8">
              <CardTitle>Jobs</CardTitle>
              <p className="text-gray-400 text-sm font-light capitalize">Roles and Functions</p>
              <div className="grid md:grid-cols-4 grid-cols-2 gap-4 pt-6">
                {Object.entries(gameStatsData.popgroups || {})
                  .filter(([_key, value]) => value !== 0)
                  .slice(0, 8)
                  .map(([key, value], index) => (
                    <React.Fragment key={index}>
                      <div className='mt-2 px-4 py-4 bg-gray-700 rounded p-2'>
                        <div className="col-span-1 md:col-span-2 font-bold text-slate-200 uppercase">{key}</div>
                        <div className="col-span-1 md:col-span-2 text-gray-400 font-black">{formatNumber(value)}</div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </BounceCard>
          </div>
          <div className="grid grid-cols-12 gap-4">
            <BounceCard className="col-span-12 md:col-span-8">
              <CardTitle>Retail</CardTitle>
              <p className="text-gray-400 text-sm font-light capitalize">Goods and Essentials</p>
              <div className="grid md:grid-cols-4 grid-cols-2 gap-6 pt-8">
                {Object.entries(retailConsumptionStats)
                  .map(([key, value], index) => (
                    <React.Fragment key={index}>
                      <div className='mt-2 px-4 py-4 bg-gray-700 rounded p-2 h-full'>
                        <div className="col-span-1 md:col-span-2 font-bold text-slate-200 uppercase">{keyDisplayNameMapping[key]}</div>
                        <div className="col-span-1 md:col-span-2 text-gray-400 font-black">{formatNumber(value)}</div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>

            </BounceCard>
            <BounceCard className="col-span-12 md:col-span-4">
              <CardTitle>Fiscal</CardTitle>
              <p className="text-gray-400 text-sm font-light capitalize">Economic Health Indicators</p>
              <div className="grid grid-cols-2 gap-4 pt-6">
                {fiscalStats.map(stat => <FiscalStatsCard key={stat.value} {...stat} />)}
              </div>
            </BounceCard>
          </div>
        </section>
      ) : (
        <div style={{
          position: 'fixed', // Fixed positioning
          top: '0', // Start from the top
          left: '0', // Start from the left
          width: '100%', // Full width
          height: '100%', // Full height
          display: 'flex', // Use flexbox for centering
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          backgroundColor: 'rgba(0, 0, 0, 0.8)' // Optional: add a semi-transparent background
        }}>
          <p className='uppercase text-3xl text-white italic leading-loose'>Loading Game Stats</p>
        </div>
      )}
    </div>
  )
}

const BounceCard = ({ className, children }) => {
  return (
    <div
      className={`group relative min-h-[300px] cursor-pointer overflow-hidden rounded-2xl bg-slate-800 p-8 ${className}`}
    >
      {children}
    </div>
  );
};

const CardTitle = ({ children }) => {
  return (
    <h3 className="mx-auto text-center text-3xl font-bold italic text-slate-500 uppercase">{children}</h3>
  );
};



export default StatsPage;