import * as React from "react";
const Logo = (props) => (
  <svg
    width={29}
    height={28}
    viewBox="0 0 29 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none">
      <path fill="#293241" d="M25 4h-9v6h3.868V7.4L25 6.11z" />
      <path
        d="M20 8.087V27h9V6zM23.7 22.9h-1.6v-1.594h1.6zm0-3.487h-1.6v-1.594h1.6zm0-3.487h-1.6V14.33h1.6zm0-3.488h-1.6v-1.594h1.6zm3.2 6.975h-1.6v-1.594h1.6zm0-3.487h-1.6V14.33h1.6zm0-3.488h-1.6v-1.594h1.6z"
        fill="#E0FBFC"
      />
      <path
        d="M10.618 11H14V4.543L9.898 0H3v6.13h7.618zM6.353 4.018H4.87v-1.64h1.482zm2.962 0H7.834v-1.64h1.481z"
        fill="#3D5A80"
      />
      <path
        d="M0 28h9V7H0zM5.3 9.655h1.6v1.607H5.3zm0 3.516h1.6v1.607H5.3zm0 3.515h1.6v1.607H5.3zm0 3.515h1.6v1.607H5.3zM2.1 9.655h1.6v1.607H2.1zm0 3.516h1.6v1.607H2.1zm0 3.515h1.6v1.607H2.1zm0 3.515h1.6v1.607H2.1z"
        fill="#98C1D9"
      />
      <path
        d="M10 27h9V11h-9zm5.3-13.382h1.6v1.584h-1.6zm0 3.465h1.6v1.585h-1.6zm-3.2-3.465h1.6v1.584h-1.6zm0 3.465h1.6v1.585h-1.6zm0 3.466h1.6v1.584h-1.6z"
        fill="#EE6C4D"
      />
    </g>
  </svg>
);
export default Logo;
