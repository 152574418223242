import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

const apiUrl = "https://tycoon-buddy-ui.replit.app/api/markets";

function MarketsPage() {
  const [marketData, setMarketData] = useState([]);
  console.log('url =', apiUrl)
  useEffect(() => {
    const fetchMarketData = async () => {
      console.log('Fetching market data...');
      try {
        const response = await axios.get(apiUrl);
        if (response && response.data) {
          let processedData = response.data.map((item) => {
            let name = item.name;
            if (typeof name === 'string') {
              const prefixPattern = /^(custom#|labor_|share_)\d*_?/;
              if (prefixPattern.test(name)) {
                name = name.replace(prefixPattern, '');
              }
            }
            let price = parseFloat(item.price) / 100;
            let change = parseFloat(item.change).toFixed(2) + '%';

            return { ...item, name, price: price.toFixed(4), change };
          });
          let sortedData = processedData
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(item => item.change !== '0.00%');
          setMarketData(sortedData);
          console.log('Market data successfully fetched:', sortedData);
        }
      } catch (error) {
        console.error('Failed to fetch market data:', error);
      }
    };
    fetchMarketData();
  }, []);

  return(
    <div className="px-4 sm:px-6 lg:px-8 bg-gray-900 ">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-300 pt-20 uppercase">Markets</h1>
          <p className="mt-2 text-sm text-gray-500 uppercase">
            Active trading volumes
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none bg-gray-900 ">

        </div>
      </div>
      <div className="bg-gray-900 mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="bg-gray-900 inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-700 bg-gray-900">
              <thead>
                <tr className="divide-x divide-gray-700 uppercase">
                  <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pl-0">
                    Commodity
                  </th>
                  <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-500">
                    Price
                  </th>
                  <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-500">
                    Change
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pr-0">
                    Total Volume
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pr-0">
                    Category
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pr-0">
                    Tradeable
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {marketData.length > 0 ? (
                  marketData.map((item, index) => (
                    <tr key={index} className="divide-x divide-gray-900">
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-[#a8e8f9] sm:pl-0 uppercase">{item.name}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-300 font-mono">{item.price}</td>
                      <td className={`whitespace-nowrap p-4 text-sm font-mono ${parseFloat(item.change) > 0 ? 'text-green-500' : 'text-red-500'}`}>{item.change}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500 font-mono">{(parseInt(item.volume) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500 font-mono capitalize">{item.category}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-0 font-mono" style={{ color: item.available ? '#a8e8f9' : '#4a5568' }}>{item.available ? 'Yes' : 'No'}</td>
                    </tr>
                  ))
                ) : (
                  <div style={{
                    position: 'fixed', // Fixed positioning
                    top: '0', // Start from the top
                    left: '0', // Start from the left
                    width: '100%', // Full width
                    height: '100%', // Full height
                    display: 'flex', // Use flexbox for centering
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    backgroundColor: 'rgba(0, 0, 0, 0.8)' // Optional: add a semi-transparent background
                  }}>
                    <p className='uppercase text-3xl text-white italic leading-loose'>Loading market data</p>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketsPage;