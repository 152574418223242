import React from 'react';
import './App.css';
import llcImage from './assets/images/llc.png'
function HomePage() {
  return (
  // <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-gray-200">
  //   <h1 className="text-4xl font-bold mb-4">TycoonBuddy</h1>
  //   <p className="text-lg">Your <a href="https://llcgame.io" target="_blank">LLCGame.io</a> Toolkit</p>
  // </div>
    <div className='bg-gray-900'>
      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-gray-900 lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <div className="hidden sm:mb-10 sm:flex">
                  {/* <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-200/30 hover:ring-gray-900/20">
                    Beginner Guide to Land, Labor, & Capitol {' '}
                    <a href="#" className="whitespace-nowrap font-semibold text-[#60A967]">
                      <span className="absolute inset-0" aria-hidden="true" />
                      🏦 <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div> */}
                </div>
                <h1 className="text-4xl font-bold tracking-tight text-[#8FB996] sm:text-6xl capitalize">
                  tycoonBuddy
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-200 uppercase font-semibold">
                  Your <a href="https://llcgame.io" target="_blank"><span className='text-blue-500'>LLCGame.io</span></a> Toolkit
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/markets"
                    className="rounded-md bg-[#7C9885] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase"
                  >
                    Markets
                  </a>
                  <a
                    href="/stats"
                    className="rounded-md bg-[#219ebc] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase"
                  >
                    Stats
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src={llcImage}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default HomePage;