import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

const apiUrl = process.env.REACT_APP_TYCOONS_API_URL;

function TycoonRankings() {
  const [tycoonRankingData, setTycoonRankingData] = useState([]);

  useEffect(() => { }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center overflow-hidden bg-gray-900">
      <section className="mx-auto max-w-full px-4 py-4 text-slate-800 bg-gray-900">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-300 pt-4 uppercase">
            Tycoon Rankings
          </h1>
          <p className="mt-2 pb-14 text-sm text-gray-500 uppercase">
            coming soon!
          </p>
        </div>
      </section>
    </div>
  )
}

export default TycoonRankings;